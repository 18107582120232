import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoMdWater } from "react-icons/io";
import { FaMagic } from "react-icons/fa";
import { BsPeopleFill } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Trais lo Wenda Sports Pub
			</title>
			<meta name={"description"} content={"Oyunun heyecanını rahat ve samimi bir atmosferle harmanlayan canlı bir spor barı olan Trais lo Wenda'a hoş geldiniz. "} />
			<meta property={"og:title"} content={"Home | Trais lo Wenda Sports Pub"} />
			<meta property={"og:description"} content={"Oyunun heyecanını rahat ve samimi bir atmosferle harmanlayan canlı bir spor barı olan Trais lo Wenda'a hoş geldiniz. "} />
			<meta property={"og:image"} content={"https://traislowenda.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://traislowenda.com/img/icon.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://traislowenda.com/img/icon.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://traislowenda.com/img/icon.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://traislowenda.com/img/icon.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://traislowenda.com/img/icon.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://traislowenda.com/img/icon.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section
			min-height="100vh"
			lg-padding="120px 0 88px 0"
			md-padding="140px 0 96px 0"
			sm-padding="60px 0 96px 0"
			quarkly-title="Hero-23"
			padding="88px 0 88px 0"
			background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.5) 67.71%,rgba(4, 8, 12, 0.6) 100%),url(https://traislowenda.com/img/1.jpg) center center/cover no-repeat"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-flex-direction="column"
				display="flex"
				lg-width="100%"
				align-items="flex-start"
				lg-align-items="center"
				lg-justify-content="center"
				width="100%"
				flex-direction="row"
			>
				<Text
					color="#FFFFFF"
					font="--lead"
					width="100%"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
					sm-margin="0px 0px 12px 0px"
					letter-spacing="2px"
				>
					Sport Pub
				</Text>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-justify-content="center"
				display="flex"
				lg-align-items="center"
				align-items="flex-end"
				lg-width="100%"
				padding="0px 0px 0px 0px"
				justify-content="space-between"
				lg-flex-direction="column"
				width="100%"
				flex-direction="row"
			>
				<Text
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="25%"
					md-width="100%"
					margin="0px 0px 0px 0px"
					lg-width="100%"
					color="--light"
					font="--headline2"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					lg-margin="0px 0px 20px 0px"
					lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Trais lo Wenda
				</Text>
				<Text
					color="#FFFFFF"
					font="--base"
					width="40%"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
				>
					Oyunun heyecanını rahat ve samimi bir atmosferle harmanlayan canlı bir spor barı olan Trais lo Wenda'a hoş geldiniz. Şehrin rahat bir noktasında yer alan mekanımız, uzun bir günün ardından dinlenmek, en sevdikleri sporlar hakkında sohbet etmek, eğlenceli aktivitelere katılmak veya sadece arkadaşlarının ve sevimli bar personelimizin tadını çıkarmak isteyenler için mükemmel bir kaçış noktasıdır.
				</Text>
			</Box>
		</Section>
		<Section padding="0px 0 0px 0" quarkly-title="Advantages/Features-22">
			<Override
				slot="SectionContent"
				max-width="none"
				width="100%"
				display="flex"
				flex-direction="row"
				flex-wrap="wrap"
				sm-flex-direction="column"
			/>
			<Box
				width="33.33%"
				background="--color-red"
				align-items="center"
				display="flex"
				justify-content="center"
				flex-direction="column"
				padding="48px 48px 48px 48px"
				sm-width="100%"
			>
				<Icon
					category="io"
					icon={IoMdWater}
					size="48px"
					color="--light"
					margin="0px 0px 8px 0px"
				/>
				<Text
					margin="0px 0px 2px 0px"
					font="--base"
					text-transform="uppercase"
					color="--light"
					text-align="center"
				>
					Rahat Spor Ruhu
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base" color="--light" text-align="center">
					Barımız, takımınız için tezahürat yapmanın arkadaşlarınızla tezahürat yapmak gibi hissedildiği stressiz bir ortamda sporun tadını çıkarmakla ilgilidir.
				</Text>
			</Box>
			<Box
				width="33.33%"
				background="--color-orange"
				display="flex"
				align-items="center"
				justify-content="center"
				flex-direction="column"
				padding="48px 48px 48px 48px"
				sm-width="100%"
			>
				<Icon
					category="fa"
					icon={FaMagic}
					size="48px"
					color="--light"
					margin="0px 0px 8px 0px"
				/>
				<Text
					margin="0px 0px 2px 0px"
					font="--base"
					text-transform="uppercase"
					color="--light"
					text-align="center"
				>
					İlgi Çekici Etkinlikler
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base" color="--light" text-align="center">
					Rahat spor sınavları, dostça dart maçları ve interaktif masa oyunu geceleri gibi rekabetçi olmayan, eğlenceli etkinliklerimize katılın.
				</Text>
			</Box>
			<Box
				width="33.33%"
				background="--color-indigo"
				display="flex"
				align-items="center"
				justify-content="center"
				flex-direction="column"
				padding="48px 48px 48px 48px"
				sm-width="100%"
			>
				<Icon
					category="bs"
					icon={BsPeopleFill}
					size="48px"
					color="--light"
					margin="0px 0px 8px 0px"
				/>
				<Text
					margin="0px 0px 2px 0px"
					font="--base"
					text-transform="uppercase"
					color="--light"
					text-align="center"
				>
					Topluluk ve Bağlantı
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base" color="--light" text-align="center">
					Trais lo Wenda sadece maç izleyebileceğiniz bir yer değil, aynı zamanda yeni arkadaşlarla tanışabileceğiniz ve ilgi çekici barmenlerimizle hikayelerinizi paylaşabileceğiniz bir alan.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-29">
			<Override
				slot="SectionContent"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
				flex-wrap="wrap"
				md-flex-direction="column"
				sm-min-width="280px"
			/>
			<Box width="20%" sm-width="100%" md-margin="0px 0px 36px 0px">
				<Text margin="0px 0px 0px 0px" font="--lead" color="--dark" sm-margin="0px 0px 20px 0px">
					Trais lo Wenda'da Yaklaşan Etkinlikler
					<br />
				</Text>
			</Box>
			<Box width="80%" sm-width="100%" md-width="100%">
				<Box
					border-width="0px 0px 1px 0px"
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
				>
					<Text color="--dark" letter-spacing="-.7px" margin="0px 0px 8px 0px" font="--headline3">
						Perşembe Gecesi Trivia
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Herkesin eğlenceli ve rahat bir ortamda bilgilerini gösterebileceği spor temalı bir trivia.
						<br />
					</Text>
				</Box>
				<Box
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Pazar Sosyalleri
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Masa oyunları, özel atıştırmalıklar ve harika arkadaşlıklar içeren haftalık bir buluşma.
						<br />
					</Text>
				</Box>
				<Box
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
					margin="0px 0px 50px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Spor Kutlamaları
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						BÖnemli spor anlarını kutlamak için sadece skordan ziyade oyunun neşesine odaklanan özel etkinlikler.
						<br />
					</Text>
				</Box>
			</Box>
		</Section>
		<Section sm-padding="60px 0 60px 0" padding="65px 0 65px 0" quarkly-title="Images-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
				>
					<Image
						left={0}
						min-height="100%"
						top={0}
						right={0}
						bottom={0}
						src="https://traislowenda.com/img/2.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://traislowenda.com/img/3.jpg"
						object-fit="cover"
						width="100%"
						top={0}
						position="absolute"
						display="block"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
			>
				<Box
					width="100%"
					overflow-x="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top="auto"
						src="https://traislowenda.com/img/4.jpg"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="25%"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					lg-text-align="center"
					sm-margin="30px 0px 0px 0px"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					md-text-align="left"
				>
					Trais lo Wenda Sports Pub: Eğlence ve dostluk oyununda her ziyaretin bir kazanç olduğu yer
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});